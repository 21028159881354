:root {
    --main-background-nums: 52, 49, 72;
    --main-text-nums: 215, 196, 158;
   
    --main-accent-nums: 144, 102, 113;
    --main-background: rgb(var(--main-background-nums), 1);
    --main-text: rgb(var(--main-text-nums), 1);
    --main-accent: rgb(var(--main-accent-nums),1);

    --app-height: 100%;
    --app-width: 100%;

    --header-height: 4rem;


}

* {
    box-sizing: border-box;
    
}
*::selection{
    background: rgb(var(--main-text-nums), 1);
    color:rgb(var(--main-background-nums), 1);
}
*:focus {
    outline: none;
    box-shadow: none;
}

html {
    font-size: calc(15px + 0.390625vw);
    overflow-x: hidden;
    -webkit-tap-highlight-color:transparent;
}
body {
    position: relative;
    z-index: -1;
}

html::-webkit-scrollbar {
    width: 19px;
    height: 8px;
    background-color: rgb(var(--main-background-nums), 1);
    
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    
}
html::-webkit-scrollbar{

}
html::-webkit-scrollbar-thumb {
   background-color: rgb(var(--main-text-nums), 1);
   border: 3px solid transparent;
   border-radius: 50px;
   background-clip: content-box;
   
    
}
html::-webkit-scrollbar-track-piece{
    margin-right: 10px;
    
  }
html::-webkit-scrollbar-track-piece:end {
    background: transparent;
    
    
    
  }

 html::-webkit-scrollbar-track-piece:start {
    background: transparent;
    
    
    
    
  }
  ::-webkit-scrollbar-button:single-button {
    
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    height: 20px;
    
    
  }
  /* Up */
  ::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0px;
    background-position: center;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='19' height='20' fill='rgb(215, 196, 158, 0.85)'><polygon points='5,15 14,15 10,9'/></svg>");
    
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    
  }

  ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='19' height='20' fill='rgb(215, 196, 158, 1)'><polygon points='5,15 14,15 10,9'/></svg>");
  }
  /* Down */
  ::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 0px;
    background-position: center;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='19' height='20' fill='rgb(215, 196, 158, 0.85)'><polygon points='5,5 14,5 10,11'/></svg>");
   
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    
    
    
  }

  ::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='19' height='20' fill='rgb(215, 196, 158, 1)'><polygon points='5,5 14,5 10,11'/></svg>");
  }



body {
    background: var(--main-background);
    
}

.flex {
    display: flex;
}

.flex-justify-center {
    justify-content: center;
}

.flex-align-center {
    align-items: center;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    padding: 0;
}




@keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fade-in-up {
    0% {
        opacity: 0;
        transform: translateY(5px)
    }

    100% {
        opacity: 1;
        transform: translateY(0px)
    }
}

@keyframes fade-in-up-50 {
    0% {
        opacity: 0;
        transform: translateY(5px)
    }

    100% {
        opacity: 0.7;
        transform: translateY(0px)
    }
}

@keyframes bounce-in-right {
    0% {
        
        transform: translateX(-1000px)
    }
    70% {
        transform: translateX(40px)
    }
    85% {
        transform: translateX(-20px);
    }

    100% {
        
        transform: translateX(0px)
    }
}
@keyframes bounce-in-left {
    0% {
        
        transform: translateX(1000px)
    }
    70% {
        transform: translateX(-40px)
    }
    85% {
        transform: translateX(20px);
    }

    100% {
        
        transform: translateX(0px)
    }
}

@keyframes scale-up {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes scale-down {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes scale-up-down {
    0% {
        transform: scale(0);
    }

    84% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes width-up {
    0% {
        width: 0;
    }

    100% {
        width: 100%
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(var(--main-text-nums), 0.4);
        box-shadow: 0 0 0 0 rgba(var(--main-text-nums), 0.4);
    }

    50% {
        -moz-box-shadow: 0 0 1000px 20px rgba(var(--main-text-nums), 0);
        box-shadow: 0 0 1000px 20px rgba(var(--main-text-nums), 0);
    }

}

/* Media Query Constants */
@media (min-width: 320px) {}

@media (min-width: 320px) {}