@import '../../../../resources/css/constants.css';

.body__container {
    position: relative;
    top: 0;
    width: 100%;
    
    background-color: var(--main-background);
    
    pointer-events: all;
}

.body__content {
    
}


