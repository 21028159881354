@import '../../../../resources/css/constants.css';


@media (min-width:320px)  {
    .socials__container{

    }
    .socials__content {
        gap: 20px
        
    }
    .socials__content a {
        font-size: 25px;
    }
    .socials__content svg{
        color: var(--main-text);
    }
    @media (hover: hover) {
        /* General Socials */
         .fa-soundcloud:hover{
            color: #F26F23;
        }
        a:hover .imdb-icon path:nth-child(1) {
            fill: #f5c518
        }
        a:hover .imdb-icon path:nth-child(2) {
            fill: #000
        }
        .fa-linkedin:hover {
            color:#0077B5;
            background: linear-gradient(white 0%, white 50%);
           

            background-size: 70% 70%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
        .fa-twitter:hover{
            color: #1DA1F2 
        }
        .fa-envelope:hover{
            color: lightgray;
        }

        
        
        .fa-instagram:hover{
            
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
            color: white;
            border-radius: 50%;

            background-size: 85% 85%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        } 


        


        

    }
    
   
}
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }