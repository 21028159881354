@import '../../../../resources/css/constants.css';




@media (min-width:320px) {
    .header__container {
        position: fixed;
        width: 100%;
        height: var(--header-height);
        box-sizing: border-box;
        transition: all 0.3s;
        z-index: 8;
        user-select: none;
        box-shadow: 0 0 0.8rem rgb(var(--main-background-nums),1);
        
    }

    .header__content {

        width: 100%;
        height: var(--header-height);
        padding: 0px 40px;
        transition: padding 0.3s;

        backdrop-filter: blur(0px);
        justify-content: space-between;


        color: var(--main-text)
    }

    .header__title {

        position: relative;
        font-family: 'Lobster';
        height: fit-content;
        z-index: 99;
        cursor: pointer;
        text-align: center;
        animation: bounce-in-right 1s;
    }

    .header__title h1 {
        font-size: calc(1.75rem - 5px);
        line-height: calc(1.75rem - 5px);
        transition: all 0.3s;


    }

    



}

@media (min-width:481px) {
    .header__content {
        padding: 0px 40px;

    }
}

@media (min-width:641px) {
    .header__content {
        padding: 0px 60px;

    }
}

@media (min-width:961px) {
    .header__content {
        padding: 0px 100px;

    }
    
}

@media (min-width:1025px) {
    .header__content {
        padding: 0px 120px;

    }
}

@media (min-width:1281px) {
    .header__content {
        padding: 0px 160px;



    }


    .header__title h1 {
        font-size: 1.6rem;

    }


}




@media (min-width:320px) {}

@media (min-width:481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width:961px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */
}

@media (min-width:1281px) {}