@import '../../../../../../resources/css/constants.css';

@media (orientation: portrait) {
    @media (min-width:320px) {
        .listen__container{
            position: relative;
            width: 100%;
            display: inline-block;
            
        }
        .listen__content{
            position: relative;
            width: 100%;
            
            height: fit-content;
            margin-top: var(--header-height);
            padding: 0px 40px;
            gap: 10px;        
        }

        .soundcloud__container {
            position: relative;
            height: 60vh;
            width: 100%;
            
        }
    }
    
    @media (min-width:481px) {
        /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    }
    
    @media (min-width:641px) {
        .listen__content{
            
            padding: 0px 60px;
                   
        }
    }
    
    @media (min-width:961px) {
        .listen__content{
            
            padding: 0px 160px;
                 
        }
    }
    
    @media (min-width:1025px) {
        /* big landscape tablets, laptops, and desktops */
    }
    
    @media (min-width:1281px) {
       
    }

/********************************************************************************************
*********************************************************************************************
*********************************************************************************************

                                        LANDSCAPE   


*********************************************************************************************
*********************************************************************************************   
********************************************************************************************/

}
@media (orientation: landscape) {
    @media (min-width:320px) {
        .listen__container{
            position: relative;
            width: 100%;
            display: inline-block;
            
        }
        .listen__content{
            position: relative;
            width: 100%;
            
            height: fit-content;
            margin-top: var(--header-height);
            padding: 0px 40px;
                  
        }
      
        .soundcloud__container {
            position: relative;
            height: 60vh;
            width: 100%;
            min-height: 400px;
        }
        
    }
    
    @media (min-width:481px) {
        
    }
    
    @media (min-width:641px) {
        .listen__content{
            
            padding: 0px 60px;
                   
        }
        

    }
    
    @media (min-width:961px) {
        .listen__content{
            
            padding: 0px 160px;
                 
        }
        .soundcloud__container {
            
            height: 63vh;
            
        }
    }
    
    @media (min-width:1025px) {
        .soundcloud__container {
            
            height: 65vh;
            
        }
    }
    
    @media (min-width:1281px) {
        .soundcloud__container {
            
            height: 70vh;
            
        }
    }
}
