@import '../../../../../../resources/css/constants.css';

.section-title{
    width: 100%
}
.section-title__decoration {
    flex-grow: 1;
    height: 3px;
    background: var(--main-text);
    border-radius: 25px;
    margin: 0px 20px
}
.section-title h1 {
    position: relative;
    text-align: center;
    color: var(--main-text);
    font-size: 2rem;
    padding: 2rem 0rem ;
    white-space: nowrap;
}
@media (orientation: portrait){
    .section-title__decoration {
        flex-grow: 1;
        height: 3px;
        background: var(--main-text);
        border-radius: 25px;
        margin: 0px 10px
    }
    @media (min-width: 320px) {
        .section-title h1{
            padding: 1rem 0rem
        }
    }
    
}
@media (orientation: landscape){
    .section-title__decoration {
        flex-grow: 1;
        height: 3px;
        background: var(--main-text);
        border-radius: 25px;
        margin: 0px 20px
    }
}

