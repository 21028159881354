@import '../../../../../../resources/css/constants.css';


@media (orientation: portrait) {
    @media (min-width:320px) {
        .projects__container {
            position: relative;
            display: inline-block;
            width: 100%;
            min-height: 100vh
        }

        .projects__content {
            position: relative;
            width: 100%;
            margin-top: var(--header-height);
            padding: 0px 40px;
            
        }

      
        .projects__project-view {
            flex-wrap: wrap;
            width: 100%;
            gap: 15px;
        }

        .grid-item {
            width: 35vw;
            height: 55vw;
            pointer-events: none;
            border-radius: 12px;
            border: 3px var(--main-text) solid
        }

        .grid-item img {
            width: 100%;
            height: 100%;
        }

        .grid-item span {
            font-size: 0.55rem;
            font-weight: 500;
            color: var(--main-text);

            display: none
        }

        .grid-item__overlay {
            border-radius: inherit;
            position: absolute;
            width: inherit;
            height: inherit;
            background-color: rgb(var(--main-text-nums), 0.95);
            gap: 10px;
            text-align: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.3s;
            pointer-events: all;
        }

        @media (hover: hover) {
            .grid-item__overlay:hover {
                opacity: 1 !important;
            }
        }

        .grid-item__overlay p {}

        .grid-item__overlay p:nth-child(1) {
            font-size: 1.3rem;
            font-weight: 600;
            color: var(--main-background);
            pointer-events: none;
        }

        .grid-item__overlay p:nth-child(2) {
            font-size: 1.1rem;
            font-weight: 500;
            color: rgb(var(--main-background-nums), 1);
        }

        .grid-item__overlay p:nth-child(3) {
            font-size: 1.2rem;
            font-weight: 600;
            color: rgb(var(--main-background-nums), 1);
        }



    }

    @media (min-width:481px) {
        /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    }

    @media (min-width:641px) {
        .projects__content {
            padding: 0px 60px;
        }
    }

    @media (min-width:961px) {
        .grid-item {
            width: 24vw;
            height: 30vw;
        }

        .grid-item img {
            width: 100%;
            height: 100%;
        }

        .grid-item__overlay {
            width: 24vw;
            height: 30vw;
        }
    }

    @media (min-width:1025px) {
        .projects__content {
            padding: 0px 160px;
        }

        .grid-item {
            width: calc(100vw / 8);
            height: calc(150vw / 8);
        }

        .grid-item img {
            width: calc(100vw / 8);
            height: calc(150vw / 8);
        }

        .grid-item__overlay {
            width: calc(100vw / 8);
            height: calc(150vw / 8);
        }
    }

    @media (min-width:1281px) {
        .grid-item {
            width: 20vw;
            height: 24vw;
        }

        .grid-item img {
            width: 100%;
            height: 100%;
        }

        .grid-item__overlay {
            width: 20vw;
            height: 24vw;
        }

        .grid-item__overlay {}

        .projects__project-view {
            gap: 30px;
        }

    }
}

/********************************************************************************************
*********************************************************************************************
*********************************************************************************************

                                        LANDSCAPE   


*********************************************************************************************
*********************************************************************************************   
********************************************************************************************/

@media (orientation: landscape) {
    @media (min-width:320px) {
        .projects__container {
            position: relative;
            display: inline-block;
            width: 100%;
            min-height: 100vh
        }

        .projects__content {
            position: relative;
            width: 100%;
            margin-top: var(--header-height);
            padding: 0px 20px;
            gap: 10px
        }



        .projects__project-view {
            flex-wrap: wrap;
            width: 100%;
            gap: 10px;
        }

        .grid-item {
            width: 40vw;
            height: 60vw;
            pointer-events: none;
            border-radius: 12px;
            border: 3px var(--main-text) solid
        }

        .grid-item img {
            width: 100%;
            height: 100%;
        }

        .grid-item span {
            font-size: 0.55rem;
            font-weight: 500;
            color: var(--main-text);

            display: none
        }

        .grid-item__overlay {
            border-radius: inherit;
            position: absolute;
            width: inherit;
            height: inherit;
            background-color: rgb(var(--main-text-nums), 0.95);
            gap: 10px;
            text-align: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.3s;
            pointer-events: all;
        }

        @media (hover: hover) {
            .grid-item__overlay:hover {
                opacity: 1 !important;
            }
        }

        .grid-item__overlay p {}

        .grid-item__overlay p:nth-child(1) {
            font-size: 1.3rem;
            font-weight: 600;
            color: var(--main-background);
            pointer-events: none;
        }

        .grid-item__overlay p:nth-child(2) {
            font-size: 1.1rem;
            font-weight: 500;
            color: rgb(var(--main-background-nums), 1);
        }

        .grid-item__overlay p:nth-child(3) {
            font-size: 1.2rem;
            font-weight: 600;
            color: rgb(var(--main-background-nums), 1);
        }



    }

    @media (min-width:481px) {
        /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    }

    @media (min-width:641px) {
        .projects__content {
            padding: 0px 60px;
        }
        .grid-item {
            width: 24vw;
            height: 30vw;
        }
        .grid-item__overlay {
            width: 24vw;
            height: 30vw;
        }
        .projects__project-view {
            
            gap: 20px;
        }
    }

    @media (min-width:961px) {
        .projects__content {
            padding: 0px 100px;
        }
        .projects__project-view {
            
            gap: 30px;
        }
        .grid-item {
            width: 18vw;
            height: 24vw;
        }

        

        .grid-item__overlay {
            width: 18vw;
            height: 24vw;
        }
    }

    @media (min-width:1025px) {
        .projects__content {
            padding: 0px 160px;
        }
        .grid-item {
            width: 18vw;
            height: 24vw;
        }
        .grid-item__overlay {
            width: 18vw;
            height: 24vw;
        }
        .projects__project-view {
            
            gap: 30px;
        }

        
    }

    @media (min-width:1281px) {
        .grid-item {
            width: 20vw;
            height: 24vw;
        }

        .grid-item__overlay {
            width: 20vw;
            height: 24vw;
        }

        .projects__project-view {
            gap: 30px;
        }

    }
}