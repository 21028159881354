@import '../../../../../../resources/css/constants.css';

@media (orientation: portrait) {
    .about__about-me {
        flex-direction: column;
        justify-content: center;
        align-items: center;



    }

    .about__content {
        flex-direction: column
    }

    @media (min-width:320px) {
        .about__container {
            display: inline-block;
            position: relative;

            width: 100%;


        }

        .about__content {

            position: relative;
            margin-top: var(--header-height);

            width: 100%;
            height: fit-content;

            padding: 0px 40px;

        }

   

        .about__about-me {
            gap: 1.5rem;
            padding: 0px 2rem
        }

        .about__about-me__item {
            gap: 0.75rem;

        }

        .about__about-me__item svg {
            width: 100%;
            height: 100%;
            color: var(--main-text)
        }

        .about__about-me__item__icon {
            width: 20%;
            aspect-ratio: 1/1;
            display: none;
        }

        .about__about-me__item h5 {
            white-space: nowrap;
            font-size: 0.9rem;
            text-align: center;

        }

        .about__about-me__item p {

            font-size: 0.7rem;
            width: 100%;
            word-wrap: break-word;
            overflow-wrap: break-word;
            hyphens: auto;



        }



        .about__about-me svg {
            height: 2rem;

        }

        .about__about-me__associations {


            width: 100%;
            justify-content: space-evenly;
            flex-direction: row;
            height: fit-content;


        }

        .about__about-me__associations h2 {
            color: var(--main-text);
            gap: calc(1rem - 4px);
            font-size: 1.4rem;
            white-space: nowrap;
            flex-direction: column !important;
        }

        .about__about-me__associations svg {
            height: 1.5em;
            width: 5em;
        }


    }

    @media (min-width:481px) {
        .about__about-me {

            padding: 0px 4rem
        }

        .about__about-me__item h5 {

            font-size: calc(1.1rem - 2px);


        }

        .about__about-me__item p {

            font-size: 0.75rem;


        }

    }

    @media (min-width:641px) {
        .about__content {
            padding: 0px 60px;

        }

        .about__content h1 {
            padding: 2rem 0rem
        }

        .about__about-me {

            padding: 0px 4.5rem
        }

        .about__about-me__associations svg {
            height: 5em;
            width: 10em;
            border-radius: 25px;



        }

        .about__about-me__item h5 {
            font-size: calc(1.4rem);
        }

        .about__about-me__item p {
            font-size: calc(5.9rem - 88px);
        }
    }

    @media (min-width:961px) {}

    @media (min-width:1025px) {
        .about__content {
            padding: 0px 160px;

        }

    }

    @media (min-width:1281px) {
        /* hi-res laptops and desktops */
    }
}

/********************************************************************************************
*********************************************************************************************
*********************************************************************************************

                                        LANDSCAPE   


*********************************************************************************************
*********************************************************************************************   
********************************************************************************************/

@media (orientation: landscape) {
    .about__about-me {
        flex-direction: row;
        justify-content: center;



    }

    .about__content {
        flex-direction: column;
    }

    @media (min-width:320px) {

        .about__container {
            display: inline-block;
            position: relative;
            
            width: 100%;
        }

        .about__content {
            position: relative;
            margin-top: var(--header-height);
            width: 100%;
            height: fit-content;
            padding: 0px 40px;
        }
        

        @media (min-height: 320px){
            .about__content h1 {
                
                font-size: 1.5rem;
                
            }
        }
        @media (min-height: 481px){
            .about__content h1 {
 
                font-size: 2rem;
                
            }
        }
        

        .about__about-me {
            width: 100%;
            gap: 2rem;
            padding: 0px 2rem
        }

        .about__about-me__item {
            width: 100%;
            gap: 1rem
        }

        .about__about-me__item img {
            width: 50%;
            aspect-ratio: 1/1
        }

        .about__about-me__item svg {
            width: 100%;
            height: 100%;
            color: var(--main-text)
        }

        .about__about-me__item__icon {
            width: 30%;
            aspect-ratio: 1/1
        }

        .about__about-me__item p {

            font-size: 0.5rem;

        }

        .about__about-me__item h5 {
            white-space: nowrap;
            font-size: 0.5rem;

        }

        .about__about-me__associations {
            border-radius: 25px;
            width: 90%;
            flex-direction: row;
            gap: 0.75rem;

            justify-content: space-evenly;


        }




    }

    @media (min-width:481px) {
       
    }

    @media (min-width:641px) {
        .about__content {
            padding: 0px 60px;

        }
        .about__content h1 {
            padding: 1rem 0px
        }
        .about__about-me h5{
            font-size: 0.7rem;
        }
        .about__about-me__associations {
            border-radius: 25px;
            width: 90%;


            height: fit-content;


        }
        


        @media (min-height: 320px) {
            .about__about-me__associations svg {
                height: 1.25em;
                width: 4em;
            }
        }

        @media (min-height: 481px) {
            .about__about-me__associations svg {
                height: 2em;
                width: 5em;
            }
            .about__about-me__item p {

                font-size: 0.60rem;
    
            }
        }


        

    }

    @media (min-width:961px) {
        .about__content {
            padding: 0px 100px;

        }
        .about__content h1 {
            padding: 1.3rem 0px
        }
        .about__about-me__item__icon{
            width: 30%
        }
        

        .about__about-me__item p {

            font-size: 0.85rem;

        }
        .about__about-me h5{
            font-size: calc(1rem - 5px);
        }
        .about__about-me__associations svg {
            height: 2.5em;
            width: 6.5em;
        }
    }

    @media (min-width:1025px) {
        
        .about__content {
            padding: 0px 120px;

        }
        .about__about-me__item__icon{
            width: 35%
        }

        .about__about-me__item p {

            font-size: 0.8rem;

        }
        .about__about-me h5{
            font-size: calc(1.4rem - 13px);
        }
        .about__about-me__associations svg {
            height: 2.5em;
            width: 5.5em;
        }
    }

    @media (min-width:1281px) {
        .about__content {
            padding: 0px 160px;

        }
        .about__about-me {}

        .about__about-me h5 {
            font-size: calc(1.2rem - 5px);
        }

        .about__about-me__item p {

            font-size: calc(0.9rem);

        }

        .about__about-me__associations {
            border-radius: 25px;
            width: 90%;

            height: fit-content;


        }

        .about__about-me__associations h2 {
            color: var(--main-text);
            gap: 16px
        }

        .about__about-me__associations svg {
            height: 2.5em;
            width: 6em;




        }


    }

    @media (min-width:1536px) {
        .about__about-me h5 {
            font-size: calc(1.5rem - 7px);
        }

        .about__about-me__item p {

            font-size: calc(1.1rem);

        }

        

        .about__about-me__associations h2 {
            color: var(--main-text);
            gap: 16px
        }

        .about__about-me__associations svg {
            height: 4em;
            width: 8em;




        }
    }
}