@import '../../../../../../../../resources/css/constants.css';

@media (min-width:320px) {
    .demo-player__container {
        position: relative;
      
    
        background-color:  rgb(var(--main-text-nums),1);
        width: 15rem;
        height: 100%;
        border-radius: 20px;
        opacity: 1;
        padding: 0px 20px;
        transform-origin: center;
        pointer-events: all;
    }
  

    .demo-player__content {
       width: 100%;
        scale: 1;
        
        
        
    }
   

    .demo-player__playpause {
        
        height: 100%;
        
    }
    .demo-player__playpause:focus {
        
        
        
    }
    .demo-player__playpause svg {
        width: 1.8rem;
        height: 1.8rem;
        color: var(--main-background);
    }

    .demo-player__track-content {
        gap: calc(1rem - 5px);
        justify-content: center;
        width: 100%;
        padding: 0px 0.5rem;

    }

    .demo-player__track-info {
        font-size: 0.6rem;
        color: var(--main-background);
        font-weight: 600;
        white-space: nowrap
    }

    .demo-player__track-slider-container {
        font-size: 0.5rem;
        
        width: 100%;
    }

    .demo-player__track-slider-duration {
        
        height: fit-content;
        color: var(--main-background);
        text-align: center;
        
        
    }

    .demo-player__track-slider {
        width: 100%;
        height: 5px;
        margin: 0px 5px;
        


    }


    .demo-player__track-slider-track {
        border-radius: 50px;
        height: 100%;
        flex-grow: 1;
        
    }

    .demo-player__track-slider-track-0 {


        background-color: var(--main-background);
        padding: 0px 10px;
    }

    .demo-player__track-slider-track-1 {


        background-color: var(--main-text);
        filter: sepia(35%)
    }

    .demo-player__track-slider-thumb {
        background-color: var(--main-background);
        height: 7px;
        width: 7px;
        transform: translateX(-3px);
        border-radius: 50%;
    }

    .demo-player__track-slider-thumb:active {
        box-shadow: none;
        border: none;
    }
    .demo-player__track-slider-thumb:hover {
        scale: 1.2
    }
}

@media (min-width:481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width:961px) {
   
}

@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */
}

@media (min-width:1281px) {
    /* hi-res laptops and desktops */
}