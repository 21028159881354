@import '../../resources/css/constants.css';

.app__container {
  position: relative;
  width: 100%;

  background-color: var(--main-background);


}

.app__content {
  width: 100%;
}

.track-vertical {
  position: absolute;
  z-index: 99999;
  right: 0px;
  top: 20px;
  height: calc(100vh - 40px);

  background-color: rgb(var(--main-background-nums, 0.8));
  filter: brightness(85%);



}
@media (min-width: 320px) {
  
  
}



@media (min-width: 961px) {
  
  
  
}