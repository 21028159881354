@import '../../../../../../resources/css/constants.css';



@media (min-width:320px)  {
    .nav-menu__container {
        z-index: 9;
        position: absolute;
        height: 100vh;
        width: 100vw;
        
        opacity: 0;
        left: 0;
        top: 0;
        transition: opacity 0.3s;
        background-color: var(--main-text);
        pointer-events: all;
        
    }
    .nav-menu__content {
        position: relative;
        height: calc(100% - 2 * var(--header-height));
        width: 100%;
        z-index: 9;
        top: var(--header-height);
        padding: 0px 20px var(--header-height) 20px;
        gap: 30px
    }
    
    .nav-menu__nav {
        
        gap: 25px;
        font-weight: 600;
        font-size: 1.3rem;
        
        color: var(--main-background)

        
    }
    .nav-menu__nav > div{
        height: var(--header-height);
        width: 100%;
        text-align: center;
        top: calc(-1 * var(--header-height));
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--main-text);
        transition: top 0.3s;
        cursor: pointer;
        z-index: 0;
        
       
    }
    .nav-menu__socials{
        
        
        

    
    
    }
    .nav-menu__socials svg{
        color: var(--main-background);
        
        height: 30px;
        width: 30px;
    }
}
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { 
    .nav-menu__content {
       
        
        padding: 0px 60px;
        
    }
}
@media (min-width:961px)  { 
    
 }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) {
    .nav-menu__content {
       
        
        padding: 0px 160px;
        
    }
 }