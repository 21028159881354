@import '../../../../resources/css/constants.css';


@media (min-width:320px) {}
.soundcloud-widget{
    position: relative;
    
    height: 100%;
    width: 100%;
    border: 3px var(--main-text) solid;
    border-radius: 10px;
    
    
}
@media (min-width:481px) {
    
}

@media (min-width:641px) {
    
}

@media (min-width:961px) {
  

}

@media (min-width:1025px) {
  
}



@media (min-width:1281px) {
   
}
