@import '../../../../../../resources/css/constants.css';

.home__container {
    overflow: hidden;
}



@media (orientation: portrait) {
    .home__hero-header {
        flex-direction: column;

    }

    @media (min-width:320px) {
        .home__container {

            position: relative;
            width: 100%;


        }


        .home__content {
            position: relative;
            height: calc(100vh - var(--header-height));
            margin-top: var(--header-height);
            width: 100%;

            padding: 0px 20px 10vh 20px;

        }

        .home__hero-header {

            gap: 15px;



        }

        .home__hero-header__image {
            opacity: 0;
            animation: fade-in 1s 0.4s ease-in-out forwards;
        }

        .home__hero-header img {
            width: calc(150px + 15vw);


            
            border-radius: 50%;
            aspect-ratio: 1/1;
            user-select: none;





            border: 5px solid var(--main-text);


        }

        .home__hero-header__text {
            width: 100%;


            color: var(--main-text);

            font-weight: 600;

            gap: 5px;

        }

        .home__hero-header__text__title {
            white-space: nowrap;
            font-size: calc(2rem);
            transform: scale(0);
            animation: scale-up 0.5s 0.6s forwards;
            flex-grow: 1;
        }




        .home__hero-header__text__subtitle {
            font-size: 0.9rem;
            color: var(--main-text);
            font-weight: 300;
            transform: scale(0);
            animation: scale-up 0.5s 0.8s forwards;
            text-align: center;
            flex-grow: 1;
        }

        .home__hero-header__text__subtitle span {
            white-space: nowrap;
        }


        .home__hero-header__text__button {
            width: 200px;
            height: 50px;
            margin: 5px 0px;
            border: 3px solid var(--main-text);
            border-radius: 50px;
            color: var(--main-background);
            font-weight: 800;
            font-size: 20px;
            background-color: var(--main-text);
            user-select: none;
            transform: scale(0);
            animation: scale-up 0.5s 1s forwards;

        }

        .home__hero-header__text__demo-player-container {


            border-radius: 20px;

            pointer-events: none;
            transform-origin: left;
            transform-style: preserve-3d;
            width: 15rem;
            transition: height 0.3s;
        }

        .home__hero-header__text__demo-player-content {
            position: absolute;
            height: 4rem;
            width: 15rem;



            pointer-events: none;
            opacity: 0;
            transform-origin: center;
            transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out, scale 0.2s ease-in-out;
            scale: 0
        }


        .fly-in-enter {
            opacity: 0;
            transform: translateZ(-500px) translateY(0px);
            scale: 0
        }

        .fly-in-enter-active {
            opacity: 1;
            transform: translateZ(0px) translateY(0px);
            scale: 1
        }

        .fly-in-enter-done {
            opacity: 1;
            transform: translateZ(0px) translateY(0px);
            scale: 1
        }

        .fly-in-exit {
            opacity: 1;
            transform: translateZ(0px) translateY(0px);
            scale: 1
        }

        .fly-in-exit-active {
            opacity: 0;
            transform: translateZ(-500px) translateY(0px);

            scale: 0
        }

        .fly-in-exit-done {
            opacity: 0;
            transform: translateZ(-500px) translateY(0px);
            scale: 0
        }

        .demo-player-enter {
            height: 0;
        }

        .demo-player-enter-active {
            height: calc(5rem)
        }

        .demo-player-enter-done {
            height: 4rem;
        }

        .demo-player-exit {
            height: 4rem;
        }

        .demo-player-exit-active {
            height: 0px;

        }

        .demo-player-exit-done {
            height: 0px;
        }


    }

    @media (min-width:481px) {
        .home__hero-header img {
            width: calc(125px + 10vw);

            border-radius: 50%;



            opacity: 0;
            animation: fade-in 1s 0.4s ease-in-out forwards;
        }


    }

    @media (min-width:641px) {
        .home__content {
            padding: var(--header-height) 160px 0
        }

        .home__hero-header {

            gap: 30px;



        }

        .home__hero-header img {
            width: calc(175px + 10vw);












        }

        .home__hero-header__text__title {
            font-size: calc(2.5rem);


        }




        .home__hero-header__text__subtitle {
            font-size: 1.2rem
        }

        .home__hero-header__text__button {
            width: calc(12rem);
            height: calc(4rem - 10px);
            font-size: calc(1.5rem);



        }
    }

    @media (min-width:961px) {
        .home__content {
            padding: var(--header-height) 160px 0
        }

        .home__hero-header img {
            width: calc(200px + 10vw);
            height: calc(200px + 10vw);




            opacity: 0;
            animation: fade-in 1s 0.4s ease-in-out forwards;
        }

        .home__hero-header__text__title {
            font-size: 2.5rem;
            transform: scale(0);
            animation: scale-up 0.5s 0.6s forwards;
        }

        .home__hero-header__text__subtitle {
            font-size: 1.2rem;
            color: var(--main-text);
            font-weight: 300;
            transform: scale(0);
            animation: scale-up 0.5s 0.8s forwards;
            text-align: center;

        }

        .home__hero-header__text__button {
            width: 9rem;
            height: 2.7rem;
            margin-top: 0.4rem;



            font-size: 1rem;



        }
    }

    @media (min-width:1025px) {

        .home__hero-header img {
            width: calc(200px + 15vw);
            height: calc(200px + 15vw);
            border-radius: 50%;
            margin: 0px 25px;



            opacity: 0;
            animation: fade-in 1s 0.4s ease-in-out forwards;
        }


    }

    @media (min-width:1281px) {
        .home__container {}

        .home__hero-header__text {
            width: 100%;

            max-width: calc(100vh);
            max-height: calc(100vh - 2 * var(--header-height));
            color: var(--main-text);

            font-weight: 600;

            gap: 10px;

        }

        .home__hero-header__text__subtitle {
            flex-direction: row;
            gap: 5px
        }



    }

}

/********************************************************************************************
*********************************************************************************************
*********************************************************************************************

                                        LANDSCAPE   


*********************************************************************************************
*********************************************************************************************   
********************************************************************************************/



@media (orientation: landscape) {
    .home__hero-header {
        flex-direction: row;

    }

    @media (min-width:320px) {
        .home__container {

            position: relative;
            width: 100%;


        }


        .home__content {
            position: relative;
            height: calc(100% - var(--header-height));
            margin-top: var(--header-height);
            width: 100%;

            padding: 0 20px 0 20px;

        }

        .home__hero-header {

            gap: 15px;



        }

        .home__hero-header__image {
            opacity: 0;
            animation: fade-in 1s 0.4s ease-in-out forwards;
        }

        .home__hero-header img {
            width: calc(125px + 10vw);


            max-width: calc(100vh - 2 * var(--header-height));
            max-height: calc(100vh - 2 * var(--header-height));
            border-radius: 50%;
            aspect-ratio: 1/1;
            user-select: none;





            border: 5px solid var(--main-text);


        }

        .home__hero-header__text {
            width: 100%;


            color: var(--main-text);

            font-weight: 600;

            gap: 5px;

        }

        .home__hero-header__text__title {
            white-space: nowrap;
            font-size: calc(2rem);
            transform: scale(0);
            animation: scale-up 0.5s 0.6s forwards;
            flex-grow: 1;
        }




        .home__hero-header__text__subtitle {
            font-size: 0.9rem;
            color: var(--main-text);
            font-weight: 300;
            transform: scale(0);
            animation: scale-up 0.5s 0.8s forwards;
            text-align: center;
            flex-grow: 1;
        }

        .home__hero-header__text__subtitle span {
            white-space: nowrap;
        }


        .home__hero-header__text__button {
            width: 200px;
            height: 50px;
            margin: 5px 0px;
            border: 3px solid var(--main-text);
            border-radius: 50px;
            color: var(--main-background);
            font-weight: 800;
            font-size: 20px;
            background-color: var(--main-text);
            user-select: none;
            transform: scale(0);
            animation: scale-up 0.5s 1s forwards;

        }

        .home__hero-header__text__demo-player-container {


            border-radius: 20px;

            pointer-events: none;
            transform-origin: left;
            transform-style: preserve-3d;
            width: 15rem;
            transition: height 0.3s;
        }

        .home__hero-header__text__demo-player-content {
            position: absolute;
            height: 4rem;
            width: 15rem;



            pointer-events: none;
            opacity: 0;
            transform-origin: center;
            transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out, scale 0.2s ease-in-out;
            scale: 0
        }


        .fly-in-enter {
            opacity: 0;
            transform: translateZ(-500px) translateY(0px);
            scale: 0
        }

        .fly-in-enter-active {
            opacity: 1;
            transform: translateZ(0px) translateY(0px);
            scale: 1
        }

        .fly-in-enter-done {
            opacity: 1;
            transform: translateZ(0px) translateY(0px);
            scale: 1
        }

        .fly-in-exit {
            opacity: 1;
            transform: translateZ(0px) translateY(0px);
            scale: 1
        }

        .fly-in-exit-active {
            opacity: 0;
            transform: translateZ(-500px) translateY(0px);

            scale: 0
        }

        .fly-in-exit-done {
            opacity: 0;
            transform: translateZ(-500px) translateY(0px);
            scale: 0
        }

        .demo-player-enter {
            height: 0;
        }

        .demo-player-enter-active {
            height: calc(5rem)
        }

        .demo-player-enter-done {
            height: 4rem;
        }

        .demo-player-exit {
            height: 4rem;
        }

        .demo-player-exit-active {
            height: 0px;

        }

        .demo-player-exit-done {
            height: 0px;
        }


    }

    @media (min-width:481px) {
        .home__hero-header img {
            width: calc(125px + 10vw);

            border-radius: 50%;



            opacity: 0;
            animation: fade-in 1s 0.4s ease-in-out forwards;
        }


    }

    @media (min-width:641px) {
        
        @media (min-height: 320px){
            .home__content {
                padding: 20px 60px 0
            }
            .home__hero-header {

                gap: 40px;
    
    
    
            }
        }
        @media (min-height: 481px){
            .home__content {
                padding: 40px 60px 0
            }
            .home__hero-header {

                gap: 50px;
    
    
    
            }
        }

        

        .home__hero-header img {
            width: calc(225px + 10vw);


        }

        .home__hero-header__text__title {
            font-size: calc(2.1rem);


        }




        .home__hero-header__text__subtitle {
            font-size: 1rem
        }

        .home__hero-header__text__button {
            width: calc(10rem);
            height: calc(4rem - 10px);
            font-size: calc(1.2rem);



        }
    }

    @media (min-width:961px) {
        .home__content {
            padding: var(--header-height) 160px 0
        }

        .home__hero-header img {
            width: calc(200px + 10vw);
            height: calc(200px + 10vw);




            opacity: 0;
            animation: fade-in 1s 0.4s ease-in-out forwards;
        }

        .home__hero-header__text__title {
            font-size: 2.5rem;
            transform: scale(0);
            animation: scale-up 0.5s 0.6s forwards;
        }

        .home__hero-header__text__subtitle {
            font-size: 1.2rem;
            color: var(--main-text);
            font-weight: 300;
            transform: scale(0);
            animation: scale-up 0.5s 0.8s forwards;
            text-align: center;

        }

        .home__hero-header__text__button {
            width: 9rem;
            height: 2.7rem;
            margin-top: 0.4rem;



            font-size: 1rem;



        }
    }

    @media (min-width:1025px) {

        .home__hero-header img {
            width: calc(200px + 15vw);
            height: calc(200px + 15vw);
            border-radius: 50%;
            margin: 0px 25px;



            opacity: 0;
            animation: fade-in 1s 0.4s ease-in-out forwards;
        }


    }

    @media (min-width:1281px) {
        .home__container {}

        .home__hero-header img {
            width: calc(200px + 17vw);
            height: calc(200px + 17vw);
            border-radius: 50%;
            margin: 0px 25px;



            opacity: 0;
            animation: fade-in 1s 0.4s ease-in-out forwards;
        }

        .home__hero-header__text {
            width: 100%;

            max-width: calc(100vh);
            max-height: calc(100vh - 2 * var(--header-height));
            color: var(--main-text);

            font-weight: 600;

            gap: 10px;

        }

        .home__hero-header__text__subtitle {
            flex-direction: row;
            gap: 5px
        }



    }

}