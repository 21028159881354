@import '../../../../../../resources/css/constants.css';


@media (min-width:320px) {


    .navigation__container {
        width: fit-content;
        height: fit-content;

    }

    .navigation__content {}

    .navigation__links {
        display: none;
        animation: bounce-in-left 1s
    }

    .nav-icon__container {
        z-index: 10;
        width: 20px;
        height: 20px;
        flex-direction: column;
        display: flex
    }

    .navigation__item {
        cursor: pointer;
        position: relative;
    }


    .navigation__links .navigation__item .custom-underline {
        position: absolute;
        height: 1px;
        width: 100%;

        transform: scaleX(0);

        bottom: -2;

        background-color: red;
        z-index: 400;
        transition: transform 0.2s ease-in-out;
    }

    @media (hover: hover ) {
        .navigation__links .navigation__item:hover .custom-underline {
            transform: scaleX(1);
        }
    }

    

    .navigation__item p {
        font-size: calc(0.75rem);
        font-weight: 400;
        transition: all 0.3s;
    }

    .navigation__resume{
        
       
        color: var(--main-text);
        
        background-color: var(--main-text);
        border-radius: 4px;
        
        font-weight: 400;
        font-family: 'Montserrat';
        

        
    }
    .navigation__resume a {
        display: block;
        font-size: calc(0.75rem);
        background-color: var(--main-background);
        color: var(--main-text);
        border: 2px var(--main-text) solid;
        cursor: pointer;
        border-radius: 4px;
        padding: 10px;
        font-weight: 400;
        font-family: 'Montserrat';
        transition: all 0.3s;
        text-decoration: none;
        
    }
    .navigation__resume a:hover, .navigation__resume a:active{
        transform: translate(-3px, -3px) !important;   
           
    }

    .nav-icon__bar {
        position: absolute;
        z-index: 11;
        width: inherit;
        height: 2px;
        transition: transform 0.3s;
        opacity: 0;
    }

    .nav-icon__bar.top {

        animation: fade-in 0.5s 0.2s forwards;
    }

    .nav-icon__bar.middle {

        animation: fade-in 0.5s 0.4s forwards;
    }

    .nav-icon__bar.bottom {

        animation: fade-in 0.5s 0.6s forwards;
    }
}

@media (min-width:481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {
    
}

@media (min-width:961px) {
    .navigation__links {
        gap: calc(1rem - 3px);
        display: flex;

    }

    .nav-icon__container {
        display: none;
    }
    .navigation__item p {
        font-size: calc(1.2rem - 7px);
    }
    .navigation__resume a{
        font-size: calc(1.2rem - 7px);
        
        
    }
}

@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */
}



@media (min-width:1281px) {

    .navigation__links .navigation__item .custom-underline {
        height: 1px
    }


}