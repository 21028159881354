@import '../../../../../../resources/css/constants.css';

@media (orientation: portrait) {
    .contact__socials-form {
        flex-direction: column;

    }

    .or-separator {

        flex-direction: row;
    }

   

    @media (min-width:320px) {
        .contact__container {
            position: relative;

            width: 100%;
            
            min-height: 100vh;
        }


        .contact__content {
            position: relative;
            top: var(--header-height);
            box-sizing: border-box;
            width: 100%;
            height: calc(100vh - var(--header-height));
            padding: 0 40px;
            
        }

      

        .contact__socials-form {
            position: relative;
            padding: 0rem 2rem;
            
            justify-content: space-around;
            flex-grow: 1;
        }

        .or-separator {
            font-size: 3rem;
            

        }

        .or-separator .separator {
            background: var(--main-text);
            width: 100%;
            height: 2px;
        }
        

        .or-separator .content {
            font-size: calc(3rem - 20px);
            padding: 0px 0.5rem;
        }

        .contact__socials {

            gap: calc(1rem - 5px)
        }

        .contact__socials-form span {
            font-size: calc(4rem - 48px);
            text-align: center;
            white-space: nowrap;
            padding: 0px 0px 0.5rem
        }

        *:active {}

        .contact__socials svg {
            color: var(--main-text);
            width: calc(3rem - 16px);
            height: calc(3rem - 16px);
            font-size: 40px;
            user-select: none;
        }

        @media (hover: hover) {
            .contact__socials a:nth-of-type(1) svg:hover {
                color: #F26F23 !important;

            }

            .contact__socials a:nth-of-type(2) svg:hover {
                color: #F5C518 !important;
                background: linear-gradient(black 50%, black 100%);



                background-size: 30px 30px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }


            .contact__socials a:nth-of-type(3) svg:hover {
                color: #0077B5 !important;

            }

            .contact__socials a:nth-of-type(4) svg:hover {
                background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
                color: white !important;
                border-radius: 100%;

                background-size: 30px 30px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }

            .contact__socials a:nth-of-type(5) svg:hover {
                color: #1DA1F2 !important;


            }
        }

        .contact__socials a:nth-of-type(1) svg:active {
            color: #F26F23 !important;

        }

        .contact__socials a:nth-of-type(2) svg:active {
            color: #F5C518 !important;
            background: linear-gradient(black 50%, black 100%);



            background-size: 30px 30px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }


        .contact__socials a:nth-of-type(3) svg:active {
            color: #0077B5 !important;

        }

        .contact__socials a:nth-of-type(4) svg:active {
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
            color: white !important;
            border-radius: 100%;

            background-size: 30px 30px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }

        .contact__socials a:nth-of-type(5) svg:active {
            color: #1DA1F2 !important;


        }


        .contact__form {
            
        }

        .contact__form form {
            gap: 20px;
            padding: 10px 0px;

        }

        .contact__form form input,
        .contact__form form textarea {
            padding: 6px 10px;
            border: 3px solid var(--main-text);
            border-radius: 4px;
            font-family: inherit;
            resize: none;
            width: 100%;


        }
        .contact__form form textarea {
            height: 20vh

        }



        .contact__form form .contact__form__button input {
            border-radius: 50px;
            border: 3px solid var(--main-text);
            background-color: var(--main-background);
            width: fit-content;
            transition: all 0.5s;
            font-weight: 600;
            color: var(--main-text);
        }

        .contact__form form .contact__form__button input:hover {

            color: var(--main-background);
            background-color: var(--main-text);

        }
    }

    @media (min-width:481px) {
        /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    }

    @media (min-width:641px) {
        .contact__content {
            padding: 0 60px;
        }
        
        .contact__socials-form{
            
            padding: 0px calc(5rem);
            
            
        }
        .contact__socials-form span {
            
            font-size: calc(2.2rem - 10px);
            padding: 0px 0px calc(3rem - 20px)
            
        }
        .contact__socials  {
            gap: 20px
            
         }
        .contact__socials svg {
            width: calc(5rem - 23px);
            height: calc(5rem - 23px);
           
        }
        .or-separator .separator {
            width: 100%;
        }  
       
        .or-separator .content{
            font-size: calc(3.2rem - 10px);
            padding: 0px 20px;
        }   
    }

    @media (min-width:961px) {
        .contact__content {
            padding: 0 120px;
        }
    }

    @media (min-width:1025px) {
        .contact__content {
            padding: 0px 160px;
        }

        .contact__socials-form {
            justify-content: space-evenly;

        }

        .contact__question {
            font-size: 2rem;

            margin: 1rem;
        }

        .contact__socials {
            flex-grow: 1;
            width: 25vw;
            text-align: center;
            font-size: calc(2.5rem - 25px);
            margin: 0px 2rem
        }

        .contact__socials .socials__content {
            gap: 2vw
        }

        .contact__form {
            flex-grow: 1;
            width: 25vw;
            margin: 0px 2rem;
            font-size: calc(2.5rem - 25px);
        }

        .contact__form form {
            width: 100%;

        }

        .or-separator {
            font-size: 1.75rem;



        }




        .contact__socials svg {
            width: 2rem;
            font-size: 5rem;
        }

        .contact__socials a:nth-of-type(2) svg:hover {
            color: black !important;
            background: linear-gradient(#F5C518 50%, #F5C518 100%);



            background-size: 2rem 2rem;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }

        .contact__socials a:nth-of-type(3) svg:hover {
            color: #0077B5 !important;

        }

        .contact__socials a:nth-of-type(4) svg:hover {
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
            color: white !important;
            border-radius: 100%;

            background-size: 1.65rem 1.65rem;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    @media (min-width:1281px) {
        /* hi-res laptops and desktops */
    }
}

/********************************************************************************************
*********************************************************************************************
*********************************************************************************************

                                        LANDSCAPE   


*********************************************************************************************
*********************************************************************************************   
********************************************************************************************/

@media (orientation: landscape) {
    .contact__socials-form {
        flex-direction: row;
        
    }

    .or-separator {

        flex-direction: column;
    }

    .or-separator .separator {
        width: 2px;
        height: 50%;
        border-radius: 50px;
    }

    @media (min-width:320px) {
        .contact__container {
            position: relative;
            width: 100%;
            height: 100vh;
        }


        .contact__content {
            position: relative;
            top: var(--header-height);
            box-sizing: border-box;
            width: 100%;
            height: calc(100vh - var(--header-height));
            padding: 0 40px;

        }

  

        .contact__socials-form {
            position: relative;
            justify-content: center;
            flex-grow: 1;
            padding: 1rem 0px;
            gap: 5px
        }

        .or-separator {
            font-size: 1.75rem;
            width: fit-content
        }

        .or-separator .separator {
            background: var(--main-text);
            height: 30%;
        }

        .or-separator .content {
            font-size: 1.2rem;
        }

        .contact__socials {

            position: relative;
            flex: 1;
            text-align: center;
            font-size: calc(2.1rem - 20px);
            justify-content: center;
            padding: 0px 20px
        }
        .contact__socials .socials__content {
            gap: 1rem;
            width: 100%;
            flex-wrap: wrap;
        }

        .contact__socials-form span {
            font-size: 1rem;
            white-space: nowrap;
            
            position: absolute;
            text-align: center;
            top: 0;
            width: calc(100% - 40px);
            font-weight: 500;
            
            
        }

        *:active {}

        .contact__socials svg {
            color: var(--main-text);
            
            
            width: 50px;
            height: 50px;
            
            user-select: none;
        }

        @media (hover: hover) {
            

            


            

            

           
        }

        .contact__socials a:nth-of-type(1) svg:active {
            color: #F26F23 !important;

        }

        .contact__socials a:nth-of-type(2) svg:active {
            color: #F5C518 !important;
            background: linear-gradient(black 50%, black 100%);



            background-size: 30px 30px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }


        .contact__socials a:nth-of-type(3) svg:active {
            color: #0077B5 !important;

        }

        .contact__socials a:nth-of-type(4) svg:active {
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
            color: white !important;
            

            background-size: 30px 30px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }

        .contact__socials a:nth-of-type(5) svg:active {
            color: #1DA1F2 !important;


        }


        .contact__form {
            gap: 10px;
            position: relative;
            text-align: center;
            flex: 1;
            font-size: calc(2.1rem - 20px);
            justify-content: center;
            padding: 0px 20px
        }

        .contact__form form {
            gap: 10px;
            padding: 40px 0px;

        }

        .contact__form form input,
        .contact__form form textarea {
            padding: 6px 10px;
            border: 3px solid var(--main-text);
            border-radius: 4px;
            font-family: inherit;
            resize: none;
            width: 100%


        }
        .contact__form form textarea{
            height: 3.5rem
        }


        .contact__form form .contact__form__button {
            width: 100%
        }
        .contact__form form .contact__form__button input {
            border-radius: 50px;
            border: 3px solid var(--main-text);
            background-color: var(--main-background);
            width: fit-content;
            
            font-weight: 600;
            color: var(--main-text);
            
        }


        @media (hover: hover) {
            .contact__form form .contact__form__button input:hover {

                color: var(--main-background) !important;
                background-color: var(--main-text);

            }
        }


    }

    @media (min-width:481px) {
        /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    }

    @media (min-width:641px) {
        .contact__socials-form span {
            
            font-size: calc(1.2rem - 3.5px);
            
            
        }
        .contact__socials svg {
            width: calc(5rem - 23px);
            height: calc(5rem - 23px);
           
        }
        .contact__form form input ,
        .contact__form form textarea {
            font-size: 0.9rem;
            
           


        }
        .contact__form form textarea {
            height: 5.5rem
            
           


        }
        
    }

    @media (min-width:961px) {
        .contact__content {
            padding: 0px 100px;
        }
        .contact__socials-form {
            
            
            padding: calc(5rem - 35px) 0px;
        }
        .contact__socials-form span {
            
            font-size: 1.2rem;
            
            
        }
        
        .contact__socials svg {
            width: calc(4.9rem - 15px);
            height: calc(4.9rem - 15px);
           
        }
        .contact__form form input ,
        .contact__form form textarea {
            font-size: 0.9rem;
            
           


        }
        .contact__form form textarea {
            height: 6.5rem
            
           


        }

    }

    @media (min-width:1025px) {
        
        .contact__content {
            padding: 0px 120px;
        }

        .contact__socials-form {
            
            
            padding: calc(5rem - 35px) 0px;
        }
        .contact__socials-form span {
            
            font-size: calc(1.7rem - 10px);
            width: calc(100% - 80px)
            
        }

        .contact__question {
            font-size: 2rem;

            margin: 1rem;
        }

        .contact__socials {
            position: relative;
            flex: 1;
            text-align: center;
            font-size: calc(2.1rem - 20px);
            justify-content: center;
            padding: 0px 40px
        }

        
        

        .contact__socials .socials__content {
            gap: 1rem;
            width: 100%;
            flex-wrap: wrap;
        }
        .contact__socials svg {
            width: calc(4.9rem - 23px);
            height: calc(4.9rem - 23px);
           
        }

        .contact__form {
            position: relative;
            text-align: center;
            flex: 1;
            font-size: calc(2.1rem - 20px);
            justify-content: center;
            padding: 0px 40px
        }
        

        .contact__form form {
            width: 100%;
            justify-self: center;

        }
        

        
        .contact__form form textarea {
            height: 6.5rem
            
           


        }
        
        .or-separator {
            font-size: 1.75rem;
            


        }
        .or-separator .separator {
            
            
            
        }

        .contact__content {

        }

        
    }

    @media (min-width:1281px) {
        .contact__socials-form span {
            font-size: 1.5rem;
            
            
        }
        .contact__socials svg {
            width: calc(4.9rem);
            height: calc(4.9rem);
        }
        .contact__form form input ,
        .contact__form form textarea {
            font-size: 0.85rem;
            
           


        }
    }
}