@import '../../../../resources/css/constants.css';

.header__external-socials .flex-column {
  padding: 0px 30px 0px 0px;
}

@media (min-width: 320px) {
  .header__external-socials {

    

    display: none;


  }

}

@media (min-width: 1025px) {
  @media (min-height: 500px) {
    .header__external-socials {
      position: fixed;
      top: calc(50% - var(--header-height));
      left: 50px;


      display: flex;
      padding: 0px 30px 0px 0px;
      border-right: 0px var(--main-text) solid;

    }

    .header__external-socials svg {
      width: 1.7rem;
      height: 1.7rem;

    }

    .header__external-socials .socials__content {
      transform: translateY(10000px);
    }
  }


}